@import "~assets/css/theme-ime";

.content--export {
  background-color: $color-export;
  height: 100%;
}

.react-daterange-picker__range-divider {
  margin-top: 5px;
}

.column.content--export__date-picker-container {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding-bottom: 30px;
  padding-top: 20px;
}

.content-grid-export {
  background-color: $color-export;
  height: 100%;
}

.content--export__date-picker {
  .react-daterange-picker__wrapper {
    .react-daterange-picker__inputGroup {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
