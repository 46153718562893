@import "~assets/css/theme-ime";

.content-grid {
  background-color: $color-civil;
  height: 100%;
}

.content-grid--content {
  overflow-y: scroll;
  height: 87vh;
  margin-top: 0 !important;
}

.content-grid--content-no-data {
  background-color: white !important;
  margin-top: 25vh !important;
}

.content-grid--content__hidden {
  display: none !important;
}

.subMenu--icon i.icon {
  float: left !important;
}

#menu-vertical {
  width: 110% !important;
  border-right: none;
}

.period__history-title{
  text-align: left;
  padding: 10px 0 20px 0;
}