@import "~assets/css/theme-ime";

.iframe-doc{
    min-height: 100vh;
    border: 0;
    border-color: transparent;
    width: 100%;
}

#back-btn.ui.button {
    margin: 5px 0;
    background-color: transparent;
}

.folderViewer__loader{
    height: 90vh;
    width: 100%;
}

.folderViewer__no-folder {
    padding-top: 30px;
    text-align: center;
}