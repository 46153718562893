@import "~assets/css/theme-ime";

.home-search {
  width: 500px;
}

.home-search-text {
  line-height: 1em;
  margin-top: 20vh;
  color: $color-civil;
  font-size: 2 * $font-size-title;
}
