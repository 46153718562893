@import url(https://fonts.googleapis.com/css?family=Arvo);
@import url(https://fonts.googleapis.com/css?family=Arvo);
@import url(https://fonts.googleapis.com/css?family=Arvo);
@import url(https://fonts.googleapis.com/css?family=Arvo);
@import url(https://fonts.googleapis.com/css?family=Arvo);
@import url(https://fonts.googleapis.com/css?family=Arvo);
@import url(https://fonts.googleapis.com/css?family=Arvo);
@import url(https://fonts.googleapis.com/css?family=Arvo);
/* #F94E4E */
body {
  font-family: "Arvo", serif;
  font-size: 0.9em;
  overflow-y: hidden; }

article {
  height: 90vh; }

.content--container__hidden {
  display: none !important; }

.content--container {
  display: block !important; }

.header .ui.container.grid .ui.left.internal.rail {
  height: auto; }

/* #F94E4E */
.iframe-doc {
  min-height: 100vh;
  border: 0;
  border-color: transparent;
  width: 100%; }

#back-btn.ui.button {
  margin: 5px 0;
  background-color: transparent; }

.folderViewer__loader {
  height: 90vh;
  width: 100%; }

.folderViewer__no-folder {
  padding-top: 30px;
  text-align: center; }

/* #F94E4E */
.home-search {
  width: 500px; }

.home-search-text {
  line-height: 1em;
  margin-top: 20vh;
  color: #097896;
  font-size: 2.5em; }

/* #F94E4E */
.label--text {
  margin-right: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  background-color: #097896 !important;
  color: white !important; }

.label--content {
  margin-left: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

/* #F94E4E */
.content-grid {
  background-color: #097896;
  height: 100%; }

.content-grid--content {
  overflow-y: scroll;
  height: 87vh;
  margin-top: 0 !important; }

.content-grid--content-no-data {
  background-color: white !important;
  margin-top: 25vh !important; }

.content-grid--content__hidden {
  display: none !important; }

.subMenu--icon i.icon {
  float: left !important; }

#menu-vertical {
  width: 110% !important;
  border-right: none; }

.period__history-title {
  text-align: left;
  padding: 10px 0 20px 0; }

.company-text {
  font-size: 2.5em;
  color: black; }

.grid-company {
  margin-top: 10% !important; }

/* #F94E4E */
.content--export {
  background-color: #25ad80;
  height: 100%; }

.react-daterange-picker__range-divider {
  margin-top: 5px; }

.column.content--export__date-picker-container {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: center !important;
          justify-content: center !important;
  -webkit-align-items: center !important;
          align-items: center !important;
  padding-bottom: 30px;
  padding-top: 20px; }

.content-grid-export {
  background-color: #25ad80;
  height: 100%; }

.content--export__date-picker .react-daterange-picker__wrapper .react-daterange-picker__inputGroup {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

/* #F94E4E */
.menu {
  width: 100%;
  height: 100%; }

.menu--link {
  font-size: 1.25em;
  text-decoration: none;
  display: inline-block;
  height: 100%;
  padding: 4vh 50px 4vh 10px;
  -webkit-transform: skewX(-15deg);
          transform: skewX(-15deg); }

.menu--link__hover:hover {
  background: rgba(255, 255, 255, 0.08);
  color: #fff !important; }

.menu--link__hide {
  display: none; }

.menu--link-civil {
  background-color: #097896; }

.menu--link-export {
  background-color: #25ad80; }

.menu--link-doc {
  background-color: #ee9209; }

.menu--link-health {
  background-color: #f4b606; }

.menu--link-technical {
  background-color: green; }

a {
  color: white;
  text-align: center; }

.content--search__hidden {
  display: none !important; }

.content--search {
  display: block !important; }

.header,
.header .ui.container {
  background: #847777;
}

.header--logo {
  margin-top: 3vh;
}

.search--result_not_found .header {
  background: none;
}

/* Override UI-Semantic CSS */

.header .ui.grid > .row {
  padding-top: unset;
  padding-bottom: unset;
}

.ui.grid.container {
  /* Erase the margin from UI-semantic */
  margin-top: unset;
  margin-bottom: unset;
}

/* #F94E4E */
.content--health {
  background-color: #f4b606;
  height: 100%;
  margin-top: 0 !important; }

.content--health__header {
  font-size: 3em !important; }

.content-grid-health {
  background-color: #f4b606;
  height: 100%; }

.health-file {
  color: #5c5c5c !important;
  opacity: 1 !important; }

.health-associated-file {
  margin-top: 2px; }

.grid-wrapper {
  width: 100%; }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

