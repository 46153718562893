@import "./assets/css/theme-ime";

body {
  font-family: $font;
  font-size: $font-size-content;
  overflow-y: hidden;
}

article {
  height: 90vh;
}

.content--container__hidden {
  display: none !important;
}

.content--container {
  display: block !important;
}

.header .ui.container.grid .ui.left.internal.rail{
  height: auto;}