@import "~assets/css/theme-ime";

.content--health {
  background-color: $color-health;
  height: 100%;
  margin-top: 0 !important;
}

.content--health__header {
  font-size: 3em !important;
}

.content-grid-health {
  background-color: $color-health;
  height: 100%;
}

.health-file{
  color: #5c5c5c !important;
  opacity: 1 !important;
}

.health-associated-file{
  margin-top: 2px;
}

.grid-wrapper {
  width: 100%;
}