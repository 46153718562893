$color-civil: #097896;
$color-technical: green;
$color-doc: rgb(238, 146, 9);
$color-export: #25ad80;
$color-health: #f4b606;
/* #F94E4E */

@import url("https://fonts.googleapis.com/css?family=Arvo");
$font: "Arvo", serif;
$font-size-title: 1.25em;
$font-size-content: 0.9em;
