@import "~assets/css/theme-ime";

.menu {
  width: 100%;
  height: 100%;
}

.menu--link {
  font-size: $font-size-title;
  text-decoration: none;
  display: inline-block;
  height: 100%;
  padding: 4vh 50px 4vh 10px;
  transform: skewX(-15deg);
}

.menu--link__hover:hover {
  background: rgba(255, 255, 255, 0.08);
  color: #fff !important;
}

.menu--link__hide {
  display: none;
}

.menu--link-civil {
  background-color: $color-civil;
}

.menu--link-export {
  background-color: $color-export;
}

.menu--link-doc {
  background-color: $color-doc;
}

.menu--link-health {
  background-color: $color-health;
}

.menu--link-technical{
  background-color: $color-technical;

}

a {
  color: white;
  text-align: center;
}
