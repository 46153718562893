.header,
.header .ui.container {
  background: #847777;
}

.header--logo {
  margin-top: 3vh;
}

.search--result_not_found .header {
  background: none;
}

/* Override UI-Semantic CSS */

.header .ui.grid > .row {
  padding-top: unset;
  padding-bottom: unset;
}

.ui.grid.container {
  /* Erase the margin from UI-semantic */
  margin-top: unset;
  margin-bottom: unset;
}
