@import "~assets/css/theme-ime";

.label--text {
  margin-right: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  background-color: $color-civil !important;
  color: white !important;
}

.label--content {
  margin-left: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
